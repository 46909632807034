<template>
  <div class="wrapper w-100">
    <div class="animated fadeIn">
      <b-row>
        <b-col cols="12" class="py-5" v-if="fetchingHarvestInformations">
          <div class="text-center text-muted">
            <b-spinner></b-spinner>
            <br>
            Données de la récolte...
          </div>
        </b-col>
        <b-col cols="12" v-else>
          <b-card header-tag="header" footer-tag="footer">
            <div slot="header">
              <i class="fa fa-shopping-cart mr-2"></i>
              <strong> Récoltes sur la production</strong>
              <strong-display v-if="productionHarvest">
                : {{productionHarvest.name}}
              </strong-display>
              <div class="card-header-actions float-right">
                <b-modal title="Enregistrer une dépense" class="modal-primary" v-model="addModalSpent"
                         @hide="onAddModalSpentClose" size="lg">
                  <b-form-row>
                    <b-col class="mr-4">
                      <c-input container-class="mb-3" label="Libellé de la dépense" placeholder="Ex: Dépense 1"
                               v-model="spentName" :state="spentNameState">
                        Veuillez saisir un nom
                      </c-input>
                      <c-input container-class="mb-3" type="quantity" label="Coût de la dépense" placeholder="Ex: 50000"
                               v-model="spentCost" :unit="exploitationCurrency"
                               step="500" :state="spentCostState">
                        Veuillez spécifier un coût
                      </c-input>
                      <div class="d-inline-flex w-100">
                        
                        <c-input container-class="mb-3" type="select" label="Catégorie de la dépense"
                                 v-model="spentCategory"
                                 :options="listSpentCategories" :state="spentCategoryState">
                          <template slot="first">
                            <option :value="null">-- Selectionnez la catégorie de la dépense --</option>
                          </template>
                          Veuillez sélectionner une personne
                        </c-input>
                        <b-button variant="dark" size="sm" pill class="add-button"
                                  title="Ajouter une nouvelle catégorie"
                                  @click="addSpentCategory" :disabled="spentCategoryNewDisplay">
                          <i class="fa fa-plus"></i>
                        </b-button>
                      </div>
                      <c-input container-class="mb-3" label="" placeholder="Créer une nouvelle catégorie emetteur"
                               v-model="spentCategoryNew" :state="spentCategoryState" v-if="spentCategoryNewDisplay">
                        Veuillez saisir un nom
                      </c-input>
                      
                      <c-input container-class="mb-3" type="datetime" label="Date d'enregistrement" v-model="spentDate"
                               :time.sync="spentTime">
                      </c-input>
                    </b-col>
                    <b-col>
                      <div class="d-inline-flex w-100">
                        <c-input container-class="mb-2" type="select" label="Emetteur de la dépense"
                                 v-model="spentEmitter"
                                 :options="spentsMembersList" :state="spentEmitterState">
                          <template slot="first">
                            <option :value="null">-- Selectionnez l'emetteur --</option>
                          </template>
                          Veuillez sélectionner une personne
                        </c-input>
                        <b-button variant="dark" size="sm" pill class="add-button" title="Ajouter un nouvel emetteur"
                                  @click="addSpentEmitter" :disabled="spentEmitterNewDisplay">
                          <i class="fa fa-plus"></i>
                        </b-button>
                      </div>
                      <c-input container-class="mb-3" label="" placeholder="Créer un nouvel emetteur"
                               v-model="spentEmitterNew" :state="spentEmitterState" v-if="spentEmitterNewDisplay">
                        Veuillez saisir un nom
                      </c-input>
                      
                      <div class="d-inline-flex w-100">
                        <c-input container-class="mb-2" type="select" label="Bénéficiaire de la dépense"
                                 v-model="spentReceiver"
                                 :options="spentsMembersList" :state="spentReceiverState">
                          <template slot="first">
                            <option :value="null">-- Selectionnez le bénéficiaire --</option>
                          </template>
                          Veuillez sélectionner une personne
                        </c-input>
                        <b-button variant="dark" size="sm" pill class="add-button" title="Ajouter un nouvel emetteur"
                                  @click="addReceiveEmitter" :disabled="spentReceiverNewDisplay">
                          <i class="fa fa-plus"></i>
                        </b-button>
                      </div>
                      <c-input container-class="mb-3" label="" placeholder="Créer un nouveau bénéficiaire"
                               v-model="spentReceiverNew" :state="spentReceiverState" v-if="spentReceiverNewDisplay">
                        Veuillez saisir un nom
                      </c-input>
                      
                      <c-input container-class="mb-3" type="textarea" label="Description de la dépense"
                               placeholder="Entrez une description..." v-model="spentDescription">
                      </c-input>
                    </b-col>
                    <b-form-file
                      v-model="spentFiles"
                      placeholder="Choose a file or drop it here..."
                      drop-placeholder="Drop file here..."
                      multiple
                    ></b-form-file>
                  </b-form-row>
                  
                  <div slot="modal-footer" class="w-100 text-center">
                    <button-spinner variant="success" type="submit" class="px-4" @click="onSubmitAddSpent"
                                    :fetching=fetchingCreateSpent>
                      Enregistrer
                    </button-spinner>
                    <b-button variant="secondary" @click="addModalSpent = false" class="ml-2">
                      Annuler
                    </b-button>
                  </div>
                </b-modal>
                <b-button varriant="secondary" :to="dashboardPage" class="mr-2"
                          title="Tableau de bord de votre production" v-b-tooltip.hover
                          v-if="harvestStatus.name !='PLANNED'">
                  <i class="fa fa-line-chart"></i>
                </b-button>
                <b-button variant="primary" @click="newSpent">
                  <i class="fa fa-plus"></i>
                  Enregistrer une dépense
                </b-button>
              </div>
              <div v-if="cultureHarvestNames.length !=0">
                <i class="fa fa-leaf mr-2 mt-1"></i>
                <strong>Cultures</strong>
                <strong-display>
                  : {{cultureHarvestNames.join(', ')}}
                </strong-display>
              </div>
            </div>
            <div class="text-center" v-if="fetchingHarvestInformations">
              <b-spinner></b-spinner>
              <br>
              Chargement...
            </div>
            <template v-else>
              <b-row>
                <b-modal :title="addModalTitle" class="modal-primary" v-model="addNodeModal" @hide="onAddModalClose">
                  <b-form>
                    <p class="text-danger" v-if="hasError">{{errorMessage}}</p>
                    
                    <b-input-group v-if="toAdd.length">
                      <b-badge variant="secondary" pill class="m-1 py-1" v-for="item in toAdd">
                        {{item.name}}
                        <b-link @click.prevent="removeItem(item)">
                          <i class="fa fa-remove text-danger"></i>
                        </b-link>
                      </b-badge>
                    </b-input-group>
                    <p class="text-center" v-else>{{noItemText}}</p>
                    
                    <b-input-group class="mb-3 mt-3">
                      <b-autocomplete-input :placeholder="searchPlaceholder" v-model="search"
                                            :data="searchAutocompleteData" class="autocomplete"
                                            @hit="submitSearch($event)" ref="searchAutocompleteInput"
                                            :serializer="item => item.name"
                                            :minMatchingChars=0>
                        <template slot="append">
                          <b-button variant="primary" @click.prevent="submitSearch()">
                            Ajouter
                          </b-button>
                        </template>
                      </b-autocomplete-input>
                    </b-input-group>
                  </b-form>
                  
                  <div slot="modal-footer" class="w-100 text-center">
                    <button-spinner variant="success" type="submit" class="px-4" @click="onSubmitAdd" :fetching=false>
                      Valider
                    </button-spinner>
                    <b-button variant="secondary" @click="addNodeModal = false" class="ml-2">
                      Annuler
                    </b-button>
                  </div>
                </b-modal>
                <b-col class="b-r-1">
                  <tree-view
                    :data="treeData"
                    :contextMenuItems=ctxMenuItems
                    :showIcons=true
                    :renameNodeOnDblClick=false
                    @nodeSelect="onNodeSelect"
                    @contextMenuItemSelect="onCtxMenuSelect"
                    nodeLabelProp=text
                    prependIconClass=fa
                    defaultIconClass="fa-cog">
                  </tree-view>
                </b-col>
                <b-col v-if="cultureSelected">
                  <h5>Récolte de : {{selectedNodeData.text}}</h5>
                  
                  <b-input-group class="mb-3 mt-3" v-if="selectedNodeData.status">
                    <b-form-text>Statut</b-form-text>
                    <b-badge class="font-sm ml-2" :variant="selectedNodeData.status.variant">
                      {{selectedNodeData.status.name}}
                    </b-badge>
                  </b-input-group>
                  <c-input container-class="mb-3" type="quantity" label="Quantité minimale estimée"
                           placeholder="Ex: 12000"
                           v-model="cultureMinEstimation" unit="Kg" :state="activityDurationState">
                    Veuillez spécifier une quantité
                  </c-input>
                  <c-input container-class="mb-3" type="quantity" label="Quantité maximale estimée"
                           placeholder="Ex: 32000"
                           v-model="cultureMaxEstimation" unit="Kg" :state="activityDurationState">
                    Veuillez spécifier une quantité
                  </c-input>
                  <div class="w-100 text-center">
                    <button-spinner variant="success" type="submit" class="px-4" @click="onSaveHarvestCulture"
                                    :fetching=fetchingSave>
                      Enregistrer
                    </button-spinner>
                    <button-spinner v-if="selectedNodeStarted" variant="danger" type="submit" class="px-4 ml-2"
                                    @click="openOtherInformationsModal">
                      Clôturer la récolte de la culture {{selectedNodeData.text}}
                    </button-spinner>
                    <button-spinner v-else variant="primary" type="submit" class="px-4 ml-2" @click=onStartStep
                                    :fetching=fetchingStartStep>
                      Démarrer la récolte de la culture {{selectedNodeData.text}}
                    </button-spinner>
                    <b-modal :title="'Informations supplémentaires - culture '+selectedNodeData.text"
                             class="modal-primary" v-model="addNewInformationsEndCultureModal"
                             @hide="onModalClose">
                      <template v-if="fetchingExistingWarehouse">
                        <div class="text-center">
                          <b-spinner></b-spinner>
                          <br>
                          <!--todo customizer tous les messages de chargement-->
                          Recherche des entrepôts...
                        </div>
                        <div slot="modal-footer"></div>
                      </template>
                      <template v-else>
                        <b-form>
                          <c-input container-class="mb-3 mt-1" type="select" label="Selectionnez un entrepôt"
                                   v-model="endHarvestWarehouse"
                                   :options="warehouses" :state="endHarvestWarehouseState">
                            <template slot="first">
                              <option :value="null" disabled>-- Sélectionnez un entrepôt --</option>
                            </template>
                            Veuillez sélectionner un entrepôt
                          </c-input>
                          <c-input container-class="mb-3" type="quantity" label="Quantité réelle recoltée"
                                   placeholder="Ex: 32000"
                                   v-model="cultureRealQuantity" unit="Kg" :state="cultureRealQuantityState">
                            Veuillez spécifier une quantité
                          </c-input>
                        </b-form>
                        
                        <div slot="modal-footer" class="w-100 text-center">
                          <!--todo penser à integrer ce composant partout ou c'est necessaire-->
                          <button-spinner variant="danger" type="submit" class="px-4 mr-3" @click="onEndStep"
                                          :fetching="fetchingEndStep">
                            Clôturer la récolte de la culture {{selectedNodeData.text}}
                          </button-spinner>
                          <b-button variant="secondary" class="ml-2">
                            Annuler
                          </b-button>
                        </div>
                      </template>
                    </b-modal>
                  </div>
                </b-col>
                <b-col v-else-if="activitySelected">
                  <h5>{{selectedNodeData.text}}</h5>
                  <p>
                    Surface considérée :
                    <default-unit>{{surfaceParcel}} ha</default-unit>
                  </p>
                  <b-input-group class="mb-3 mt-3" v-if="selectedNodeData.status">
                    <b-form-text>Statut</b-form-text>
                    <b-badge class="font-sm ml-2" :variant="selectedNodeData.status.variant">
                      {{selectedNodeData.status.name}}
                    </b-badge>
                  </b-input-group>
                  
                  <text-recommended>La durée recommandée est de : {{activityRecommendationDuration}} jours
                  </text-recommended>
                  <c-input container-class="mb-3" type="quantity" label="Durée de l'activité" placeholder="Ex: 12"
                           v-model="activityDuration" unit="Jour(s)" :state="activityDurationState">
                    Veuillez spécifier une durée
                  </c-input>
                  
                  <text-recommended>Le prix recommandé est :
                    {{activityRecommendationCost +' '+ exploitationCurrency +' /pers. / jour'}}
                  </text-recommended>
                  <c-input container-class="mb-3" type="quantity" label="Coût de l'activité" placeholder="Ex: 50000"
                           v-model="activityCost" :unit="exploitationCurrency + ' / pers. / jour'"
                           :state="activityCostState">
                    Veuillez spécifier un coût
                  </c-input>
                  
                  <text-recommended>Le nombre de personnes recommandé est de : {{activityRecommendationPersonNumber}}
                    personnes
                  </text-recommended>
                  <b-input-group v-if="activityStaff.length">
                    <b-badge variant="secondary" pill class="m-1 py-1" v-for="item in activityStaff">
                      {{item.name}}
                      <b-link @click.prevent="removeStaff(item)">
                        <i class="fa fa-remove text-danger"></i>
                      </b-link>
                    </b-badge>
                  </b-input-group>
                  <p class="text-center text-muted" v-else>Aucun employé dans cette activité.</p>
                  
                  <b-input-group class="mb-3">
                    <b-autocomplete-input placeholder="Rechercher un employé..." v-model="staffSearch"
                                          :data="staffAutocompleteData" class="autocomplete"
                                          @hit="addStaff($event)" ref="staffAutocompleteInput"
                                          :serializer="item => item.name"
                                          :minMatchingChars=0>
                      <template slot="append">
                        <b-button variant="primary" @click.prevent="addStaff()">
                          Ajouter
                        </b-button>
                      </template>
                    </b-autocomplete-input>
                  </b-input-group>
                  <template v-if="activityInputs.length != 0">
                    <label class="text-muted font-weight-bold">Intrants</label>
                    <b-input-group class="mb-3" v-for="(input, i) in activityInputs">
                      <text-recommended>
                        Recommandation :
                        {{activityRecommendationInputs[i].quantity+' ' + input.unit+' '+input.inputName}}
                      </text-recommended>
                      <b-input-group-prepend class="w-75">
                        <b-input-group-text class="w-100">{{input.inputName}}</b-input-group-text>
                      </b-input-group-prepend>
                      <b-form-input type="number" class="form-control" placeholder="Qté"
                                    v-model="input.quantity" :state="activityInputQuantityState[i]"
                                    :aria-describedby="`activity-input-quantity-${i}-feedback`" min="0" step="1" />
                      <b-input-group-append class="unit">
                        <b-input-group-text class="w-100">{{input.unit}}</b-input-group-text>
                      </b-input-group-append>
                      <b-form-invalid-feedback :id="`activity-input-quantity-${i}-feedback`">
                        Veuillez entrer une quantité
                      </b-form-invalid-feedback>
                    </b-input-group>
                  </template>
                  
                  <template v-if="activityEquipments.length !=0">
                    <label class="text-muted font-weight-bold">Equipements</label>
                    <b-input-group class="mb-3" v-for="(equipment, i) in activityEquipments">
                      <text-recommended>
                        Recommandation : {{activityRecommendationEquipments[i].quantity+' '+equipment.equipmentName}}
                      </text-recommended>
                      <b-input-group-prepend class="w-75">
                        <b-input-group-text class="w-100">{{equipment.equipmentName}}</b-input-group-text>
                      </b-input-group-prepend>
                      <b-form-input type="number" class="form-control" placeholder="Qté"
                                    v-model="equipment.quantity" :state="activityEquipmentQuantityState[i]"
                                    :aria-describedby="`activity-equipment-quantity-${i}-feedback`" min="0" step="1" />
                      <b-input-group-append class="unit">
                        <b-input-group-text class="w-100">{{equipment.unit}}</b-input-group-text>
                      </b-input-group-append>
                      <b-form-invalid-feedback :id="`activity-equipment-quantity-${i}-feedback`">
                        Veuillez entrer une quantité
                      </b-form-invalid-feedback>
                    </b-input-group>
                  </template>
                  
                  <div class="w-100 text-center">
                    <button-spinner variant="success" type="submit" class="px-4" @click="onUpdateActivity"
                                    :fetching=false>
                      Enregistrer
                    </button-spinner>
                    <button-spinner v-if="selectedNodeStarted" variant="danger" type="submit" class="px-4 ml-2"
                                    @click="onEndActivity" :fetching=fetchingStartActivity>
                      Clôturer l'activité
                    </button-spinner>
                    <button-spinner v-else variant="primary" type="submit" class="px-4 ml-2" @click="onStartActivity"
                                    :fetching=fetchingStartActivity>
                      Démarrer l'activité
                    </button-spinner>
                  </div>
                </b-col>
                <b-col v-else>
                  <h5>Récoltes</h5>
                  
                  <b-input-group class="mb-3 mt-3" v-if="harvestStatus">
                    <b-form-text>Statut</b-form-text>
                    <b-badge class="font-sm ml-2" :variant="harvestStatus.variant">
                      {{harvestStatus.name}}
                    </b-badge>
                  </b-input-group>
                  
                  <c-input container-class="mb-3 mt-3" label="Nom de la récolte" placeholder="Ex: Cacao 2019"
                           v-model="harvestName">
                    Veuillez saisir un nom
                  </c-input>
                  
                  <c-input container-class="mb-3" type="textarea" label="Description"
                           placeholder="Entrez une description..." v-model="harvestDescription">
                  </c-input>
                  
                  <c-input container-class="mb-3" type="datetime" label="Date de lancement" v-model="harvestStartDate"
                           :time.sync="harvestStartTime" :disabled="fieldDisabled">
                  </c-input>
                  
                  <div class="w-100 text-center">
                    <button-spinner variant="success" type="submit" class="px-4" @click="onSaveHarvest"
                                    :fetching=fetchingSaveHarvest>
                      Enregistrer
                    </button-spinner>
                    <button-spinner v-if="selectedNodeStarted" variant="danger" type="submit" class="px-4 ml-2"
                                    @click="openOtherModal" :fetching=fetchingSaveAndLaunchHarvest>
                      Clôturer les récoltes
                    </button-spinner>
                    <button-spinner v-else variant="primary" type="submit" class="px-4 ml-2"
                                    @click="onSaveAndLaunchHarvest"
                                    :fetching=fetchingSaveAndLaunchHarvest>
                      Lancer les récoltes
                    </button-spinner>
                    <b-modal title="Informations supplémentaires" class="modal-primary"
                             v-model="addNewInformationsEndHarvestModal"
                             @hide="onModalClose" :size="sizeToDisplay">
                      <template v-if="fetchingExistingWarehouse">
                        <div class="text-center">
                          <b-spinner></b-spinner>
                          <br>
                          <!--todo customizer tous les messages de chargement-->
                          Recherche des entrepôts...
                        </div>
                        <div slot="modal-footer"></div>
                      </template>
                      <template v-else>
                        <b-form-row>
                          <b-col :cols="widthToDisplay"
                                 v-for="(item,indice) in endHarvestCultureQuantities">
                            <b-card border-variant="light" :header="'Culture de '+item.cultureName" class="text-center">
                              <c-input container-class="mb-3 mt-1" type="select" label="Selectionnez un entrepôt"
                                       v-model="item.warehouseId"
                                       :options="warehouses"
                                       :state="endHarvestCultureQuantitiesState[indice].warehouseId">
                                <template slot="first">
                                  <option :value="null" disabled>-- Sélectionnez un entrepôt --</option>
                                </template>
                                Veuillez sélectionner un entrepôt
                              </c-input>
                              <c-input container-class="mb-3" type="quantity" label="Quantité réelle recoltée"
                                       placeholder="Ex: 32000"
                                       v-model="item.realQuantity" unit="Kg"
                                       :state="endHarvestCultureQuantitiesState[indice].realQuantity">
                                Veuillez spécifier une quantité
                              </c-input>
                            </b-card>
                          </b-col>
                        </b-form-row>
                        
                        <div slot="modal-footer" class="w-100 text-center">
                          <!--todo penser à integrer ce composant partout ou c'est necessaire-->
                          <button-spinner variant="danger" type="submit" class="px-4 mr-3" @click="onEnd('fetchingEnd')"
                                          :fetching="fetchingEnd">
                            Clôturer effectivement vos récoltes
                          </button-spinner>
                          <b-button variant="secondary" class="ml-2">
                            Annuler
                          </b-button>
                        </div>
                      </template>
                    </b-modal>
                  </div>
                </b-col>
              </b-row>
            </template>
          </b-card>
        </b-col>
      </b-row>
    </div>
  </div>

</template>

<script>
  import TreeView from "../../../components/tree-component/components/TreeView"
  import {Api, Toast, price, Regex} from '../../../helpers'
  import Multiselect from 'vue-multiselect'
  
  class TreeNode {
    children = []
    parent = null
    
    constructor(props) {
      this.fill(props)
    }
    
    fill(props) {
      for (let key in props) {
        if (props.hasOwnProperty(key))
          this[key] = props[key]
      }
    }
    
    toJSON() {
      const obj = {}
      for (let key in this) {
        if (this.hasOwnProperty(key) && key !== "children")
          obj[key] = this[key]
      }
      // if (this.children && this.children.length)
      //   obj.children = this.children.map(child => child.toJSON())
      
      return obj
    }
  }
  
  export default {
    name: "Harvest",
    components: {Multiselect, TreeView},
    data() {
      return {
        fetchingSaveHarvest: false,
        fetchingSaveAndLaunchHarvest: false,
        productionHarvest: null,
        cultureHarvestNames: [],
        testMulti: null,
        options: [
          {
            name: 'adrien',
            code: '0'
          },
          {
            name: 'stephane',
            code: '1'
          },
          {
            name: 'hereads',
            code: '2'
          },
          {
            name: 'adasdfsdrien',
            code: '3'
          },
        ],
        productionsCultures: [],
        culturesForProduction: [],
        
        fetchingHarvestInformations: false,
        fetchingEndStep: false,
        fetchingEnd: false,
        fetchingStartActivity: false,
        fetchingStartStep: false,
        
        error: null,
        submittedHarvest: false,
        submittedCulture: false,
        submittedActivity: false,
        submittedEndCulture: false,
        submittedAllCutlureQuantities: false,
        submittedEndHarvestWarehouses: false,
        
        harvestStatus: null,
        harvestName: '',
        harvestDescription: '',
        harvestStartDate: '',
        harvestStartTime: '',
        
        addNodeModal: false,
        toAdd: [],
        search: '',
        activities: [],
        
        nodeTypes: {
          Recolte: {
            icon: "fa-cog",
            ctxMenuItems: [],
            children: ["Culture"],
            modal: {
              noItemText: "Aucune culture sélectionnée.",
              searchPlaceholder: "Ajouter une culture...",
              autocompleteData: "cultures"
            }
          },
          Culture: {
            icon: "fa-leaf",
            ctxMenuItems: [
              {code: "ADD_CHILD", label: "Ajouter des activités"},
              {code: "DEL", label: "Supprimer la culture"}
            ],
            children: ["Activity"],
            modal: {
              noItemText: "Aucune activité sélectionnée.",
              searchPlaceholder: "Ajouter une activité...",
              autocompleteData: "activities"
            },
            refKey: "cultureId"
          },
          Activity: {
            icon: "fa-gavel",
            ctxMenuItems: [
              {code: "DEL", label: "Supprimer l'activité"}
            ],
            children: [],
            refKey: "activityId",
            properties: [
              {key: "duration", defaultValue: ''},
              {key: "cost", defaultValue: ''},
              {key: "staff", defaultValue: () => []},
              {
                key: "inputs",
                defaultValue: model => model.inputs.map(el => ({
                  inputId: el.id,
                  inputName: el.name,
                  quantity: 0,
                  unit: el.unit
                }))
              },
              {
                key: "equipments",
                defaultValue: model => model.equipments.map(el => ({
                  equipmentId: el.id,
                  equipmentName: el.name,
                  quantity: 0,
                  unit: el.unit
                }))
              }
            ]
          }
        },
        harvestData: new TreeNode({
          id: 0, text: "Récoltes", type: "Recolte"
        }),
        ctxMenuItems: [],
        selectedNode: null,
        
        cultureMinEstimation: '',
        cultureMaxEstimation: '',
        
        activityDuration: '',
        activityCost: '',
        activityStaff: [],
        staffSearch: '',
        activityInputs: [],
        activityEquipments: [],
        staff: [],
        
        activityRecommendationDuration: null,
        activityRecommendationCost: null,
        activityRecommendationPersonNumber: null,
        activityRecommendationInputs: [],
        activityRecommendationEquipments: [],
        defaultsParameters: [],
        
        addNewInformationsEndCultureModal: false,
        addNewInformationsEndHarvestModal: false,
        fetchingExistingWarehouse: false,
        warehouses: [],
        endHarvestWarehouse: null,
        cultureRealQuantity: '',
        endHarvestCultureQuantities: [],
        surfaceParcel: '',
        
        addModalSpent: false,
        
        spentName: '',
        spentCost: '',
        spentCategory: null,
        spentCategoryNew: "",
        spentCategoryNewDisplay: false,
        spentDate: "",
        spentTime: "",
        spentEmitter: null,
        spentReceiver: null,
        spentDescription: '',
        fetchingCreateSpent: false,
        submittedSpent: false,
        spentFiles: [],
        spentEmitterNew: "",
        spentReceiverNew: "",
        spentsMembers: [],
        spentEmitterNewDisplay: false,
        spentReceiverNewDisplay: false,
        spentCategories: [],
      }
    },
    created() {
      this.fetchingHarvestInformations = true
      Api.get('/exploitation/production/harvest', {
        exploitationId: this.exploitationId,
        harvestId: this.harvestId
      })
        .then(res => {
          if (res.data.status === 'success' && res.data.data) {
            const harvestData = res.data.data
            this.setHarvestData(harvestData)
          }
          else {
            this.error = res.data.error
          }
        })
        .catch(error => {
          this.error = {
            message: 'Echec de la connexion au serveur'
          }
        })
        .then(() => {
          this.fetchingHarvestInformations = false
        })
      Api.get('/exploitation/member/all', {
        exploitationId: this.exploitationId
      })
        .then(res => {
          if (res.data.status === 'success' && res.data.data) {
            this.spentsMembers = res.data.data
          }
          else {
            this.error = res.data.error
          }
        })
        .catch(error => {
          this.error = {
            message: 'Echec de la connexion au serveur'
          }
        })
        .then(() => {
        })
      Api.get('/exploitation/spent-category/list', {
        exploitationId: this.exploitationId
      })
        .then(res => {
          if (res.data.status === 'success' && res.data.data) {
            this.spentCategories = res.data.data
          }
          else {
            this.error = res.data.error
          }
        })
        .catch(error => {
          this.error = {
            message: 'Echec de la connexion au serveur'
          }
        })
        .then(() => {
        })
      Api.get('/exploitation/activity/all', {
        exploitationId: this.exploitationId
      })
        .then(res => {
          if (res.data.status === 'success' && res.data.data) {
            this.activities = res.data.data
          }
          else {
            this.error = res.data.error
          }
        })
        .catch(error => {
          this.error = {
            message: 'Echec de la connexion au serveur'
          }
        })
        .then(() => {
        
        })
      Api.get('/exploitation/staff/list', {
        exploitationId: this.exploitationId
      })
        .then(res => {
          if (res.data.status === 'success' && res.data.data) {
            this.staff = res.data.data
          }
          else {
            this.error = res.data.error
          }
        })
        .catch(error => {
          this.error = {
            message: 'Echec de la connexion au serveur'
          }
        })
        .then(() => {
          this.fetchingStaffList = false
        })
    },
    watch: {
      error(e) {
        if (e)
          Toast.error(e)
      }, /*
      activityDuration(){
  
        this.activityRecommendationDuration = this.activityDuration
        this.activityRecommendationPersonNumber = Math.ceil((this.defaultsParameters.personNumber * this.defaultsParameters.duration * this.surfaceParcel) / this.activityRecommendationDuration)
        this.activityRecommendationEquipments = this.activityRecommendationEquipments.map((item, i) => ({
          ...item,
          quantity: Math.ceil((this.activityRecommendationPersonNumber * this.defaultsParameters.equipments[i].quantity) / this.defaultsParameters.personNumber)
        }))
      }*/
    },
    computed: {
      exploitationId() {
        return this.$store.getters.exploitationId
      },
      harvestId() {
        return this.$route.params.harvestId
      },
      dashboardPage() {
        return '/exploitation/' + this.exploitationId + '/production/harvest/' + this.harvestId + '/dashboard'
      },
      treeData() {
        const harvestData = this.harvestData
        if (this.harvestName)
          harvestData.text = this.harvestName
        else
          harvestData.text = 'Recolte ' + new Date().toDateString()
        
        return [harvestData]
      },
      cultureSelected() {
        return this.selectedNode && this.selectedNode.data.type === "Culture"
      },
      activitySelected() {
        return this.selectedNode && this.selectedNode.data.type === "Activity"
      },
      endHarvestWarehouseState() {
        return !this.submittedEndCulture || this.endHarvestWarehouse != null ? null : false
      },
      cultureRealQuantityState() {
        return !this.submittedEndCulture || Regex.isPositiveNumber(this.cultureRealQuantity) ? null : false
      },
      endHarvestCultureQuantitiesState() {
        return !this.submittedEndHarvestWarehouses ? this.endHarvestCultureQuantities.map(cult => ({
          warehouseId: null,
          realQuantity: null
        })) : this.endHarvestCultureQuantities.map(cult => ({
          warehouseId: cult.warehouseId != null ? null : false,
          realQuantity: Regex.isPositiveNumber(cult.realQuantity) ? null : false
        }))
      },
      activityDurationState() {
        return !this.submittedActivity || Regex.isPositiveNumber(this.activityDuration) ? null : false
      },
      activityCostState() {
        return !this.submittedActivity || Regex.isPositiveNumber(this.activityCost) ? null : false
      },
      activityInputQuantityState() {
        return !this.submittedActivity ? [null] : this.activityInputs.map(input => {
          return Regex.isPositiveNumber(input.quantity) ? null : false
        })
      },
      activityEquipmentQuantityState() {
        return !this.submittedActivity ? [null] : this.activityEquipments.map(equip => {
          return Regex.isPositiveNumber(equip.quantity) ? null : false
        })
      },
      staffAutocompleteData() {
        return this.staff
      },
      selectedNodeData() {
        return this.selectedNode && this.selectedNode.data ? this.selectedNode.data : {}
      },
      exploitationCurrency() {
        return price()
      },
      harvestStartDateTime() {
        return this.harvestStartDate + ' ' + this.harvestStartTime
      },
      selectedNodeStarted() {
        if (this.selectedNode && this.selectedNode.data.type !== "Recolte")
          return this.selectedNode.data.status && this.selectedNode.data.status.name == 'RUNNING'
        
        return this.harvestStatus && this.harvestStatus.name == 'RUNNING'
      },
      fieldDisabled() {
        return this.harvestStatus.name != 'PLANNED'
      },
      widthToDisplay() {
        if (this.endHarvestCultureQuantities.length >= 2)
          return 6
        else return ''
      },
      sizeToDisplay() {
        if (this.endHarvestCultureQuantities.length >= 2)
          return 'lg'
        else return ''
      },
      spentNameState() {
        return !this.submittedSpent || this.spentName.trim().length >= 3 ? null : false
      },
      spentCostState() {
        return !this.submittedSpent || Regex.isPositiveNumber(this.spentCost) ? null : false
      },
      spentCategoryState() {
        return !this.submittedSpent || (this.spentCategory != null || this.spentCategoryNew.trim().length >= 3) ? null : false
      },
      spentEmitterState() {
        return !this.submittedSpent || (this.spentEmitter != null || this.spentEmitterNew.trim().length >= 3) ? null : false
      },
      spentReceiverState() {
        return !this.submittedSpent || (this.spentReceiver != null || this.spentReceiverNew.trim().length >= 3) ? null : false
      },
      listSpentCategories() {
        return this.spentCategories.map(cat => ({
          text: cat.name,
          value: cat
        }))
      },
      spentsMembersList() {
        return this.spentsMembers.map(member => ({
          text: member.name,
          value: member
        }))
      },
      
      // Modal
      addModalTitle() {
        if (!this.selectedNode)
          return ''
        
        return this.nodeTypes[this.selectedNode.data.type].ctxMenuItems.length != 0
          ? this.nodeTypes[this.selectedNode.data.type].ctxMenuItems[0].label
          : ""
      },
      noItemText() {
        if (!this.selectedNode)
          return ''
        
        const modal = this.nodeTypes[this.selectedNode.data.type].modal
        return modal ? modal.noItemText : null
      },
      searchPlaceholder() {
        if (!this.selectedNode)
          return ''
        
        const modal = this.nodeTypes[this.selectedNode.data.type].modal
        return modal ? modal.searchPlaceholder : null
      },
      searchAutocompleteData() {
        if (!this.selectedNode)
          return []
        
        const modal = this.nodeTypes[this.selectedNode.data.type].modal
        return modal ? this[modal.autocompleteData] : []
      },
    },
    methods: {
      onCtxMenuSelect({code}, node) {
        const args = code.split('-')
        switch (args[0]) {
          case "ADD_CHILD":
            this.addNodeModal = true
            break
          case "DEL":
            this.deleteNode(node.data.id)
            break
        }
        return true
      },
      onNodeSelect(node, selected) {
        if (selected) {
          this.selectedNode = node
          this.ctxMenuItems = this.nodeTypes[node.data.type].ctxMenuItems
          if (node.data.type == 'Activity') {
            this.submittedActivity = false
            this.activityDuration = node.data.duration
            this.activityCost = node.data.cost
            this.activityInputs = node.data.inputs
            this.activityEquipments = node.data.equipments
            this.activityStaff = node.data.staff
            
            
            // we begin with recommandations
            this.defaultsParameters = {
              duration: node.data.duration,
              cost: node.defaultsParameters,
              personNumber: node.data.personNumber,
              inputs: node.data.inputs,
              equipments: node.data.inputs
            }
            const valuePersonNumber = Math.ceil(this.surfaceParcel / 10)
            if (valuePersonNumber <= 1 || valuePersonNumber <= this.defaultsParameters.personNumber) {
              this.activityRecommendationPersonNumber = this.defaultsParameters.personNumber
              this.activityRecommendationDuration = this.multiplyWithSurface(this.defaultsParameters.duration * this.defaultsParameters.personNumber / this.activityRecommendationPersonNumber)
            } else {
              this.activityRecommendationPersonNumber = valuePersonNumber
              this.activityRecommendationDuration = this.multiplyWithSurface(this.defaultsParameters.duration * this.defaultsParameters.personNumber / this.activityRecommendationPersonNumber)
            }
            this.activityRecommendationCost = this.activityCost
            this.activityRecommendationInputs = this.activityInputs.map(item => ({
              ...item,
              quantity: this.multiplyWithSurface(item.quantity)
            }))
            this.activityRecommendationEquipments = this.activityEquipments.map(item => ({
              ...item
            }))
          }
          else if (node.data.type == 'Culture') {
            this.submittedCulture = false
            this.cultureMinEstimation = node.data.minEstimation || ''
            this.cultureMaxEstimation = node.data.maxEstimation || ''
          }
        }
      },
      recommend() {
        this.activityRecommendationDuration = this.activityDuration
        this.activityRecommendationPersonNumber = Math.ceil((this.defaultsParameters.personNumber * this.defaultsParameters.duration * this.surfaceParcel) / this.activityRecommendationDuration)
        this.activityRecommendationEquipments = this.activityRecommendationEquipments.map((item, i) => ({
          ...item,
          quantity: Math.ceil((this.activityRecommendationPersonNumber * this.defaultsParameters.equipments[i].quantity) / this.defaultsParameters.personNumber)
        }))
      },
      recommendFunction() {
        setTimeout(() => this.recommend(), 1000)
      },
      deleteNode(id, root = this.harvestData) {
        if (root.children) {
          const childrenLength = root.children.length
          root.children = root.children.filter(el => el.id !== id)
          if (childrenLength === root.children.length)
            root.children.forEach(node => this.deleteNode(id, node))
        }
      },
      findNode(id, root = this.harvestData) {
        if (root.id === id)
          return root
        if (root.children) {
          let node
          for (let i = 0; i < root.children.length; i++) {
            node = this.findNode(id, root.children[i])
            if (node)
              return node
          }
        }
        return null
      },
      setHarvestData(data = {}) {
        this.harvestStatus = data.status
        this.harvestName = data.name
        this.harvestDescription = data.description
        if (data.startDate) {
          const dateParts = data.startDate.split(' ')
          if (dateParts.length)
            this.harvestStartDate = dateParts[0]
          if (dateParts.length >= 2)
            this.harvestStartTime = dateParts[1].toString()
        }
        this.productionHarvest = data.production
        this.surfaceParcel = data.production.parcel.area
        this.cultureHarvestNames = []
        this.harvestData = new TreeNode({
          id: data.id,
          text: data.name,
          icon: this.nodeTypes.Recolte.icon,
          type: "Recolte",
          children: data.cultures.map(culture => {
            const cultureNode = new TreeNode()
            this.cultureHarvestNames.push(culture.name)
            cultureNode.fill({
              ...culture,
              id: "Culture-" + culture.id,
              icon: this.nodeTypes.Culture.icon,
              type: "Culture",
              text: culture.name,
              cultureId: culture.id,
              parent: this.harvestData,
              ctxMenuItems: [
                {code: "ADD_CHILD", label: "Ajouter des activités"},
                {code: "DEL", label: "Supprimer la culture"}
              ],
              actions: [
                {
                  icon: 'fa-plus',
                  text: 'Ajouter act'
                }, {
                  icon: 'fa-minus',
                  text: 'Supprimer culture',
                }
              ],
              children: culture.activities.map(activity => new TreeNode({
                ...activity,
                id: "Activity-" + activity.id,
                icon: this.nodeTypes.Activity.icon,
                type: "Activity",
                text: activity.name,
                activityId: activity.id,
                parent: cultureNode,
                status: activity.status,
                personNumber: activity.personNumber,
                children: [],
                staff: activity.staff,
                inputs: activity.inputs.map(input => ({
                  ...input,
                  inputId: input.id,
                  inputName: input.name
                })),
                equipments: activity.equipments.map(equipment => ({
                  ...equipment,
                  equipmentId: equipment.id,
                  equipmentName: equipment.name
                })),
                actions: [{
                  icon: 'fa-minus',
                  text: 'Supprimer act',
                }],
                ctxMenuItems: [
                  {code: "DEL", label: "Supprimer l'activité"}
                ],
                //to verifiy if it's an activity to display the real color in the TreeNode component
                isActivity: true
              }))
            })
            return cultureNode
          })
        })
      },
      getHarvestData() {
        return this.harvestData.children.map(culture => ({
          cultureId: culture.cultureId,
          minEstimation: culture.minEstimation,
          maxEstimation: culture.maxEstimation,
          activities: culture.children
        }))
      }
      ,
      onSaveHarvestCulture(noToast) {
        this.submittedCulture = true
        if (!this.validCulture()) return
        this.selectedNode.data.minEstimation = this.cultureMinEstimation
        this.selectedNode.data.maxEstimation = this.cultureMaxEstimation
        if (!noToast)
          Toast.success("Estimations de la récolte du ", this.selectedNode.text, " enregistrée avec succès")
      },
      onUpdateActivity(noToast) {
        this.submittedActivity = true
        if (!this.validActivity()) return
        this.selectedNode.data.duration = this.activityDuration
        this.selectedNode.data.cost = this.activityCost
        this.selectedNode.data.staff = this.activityStaff
        this.selectedNode.data.inputs = this.activityInputs
        this.selectedNode.data.equipments = this.activityEquipments
        if (!noToast)
          Toast.success("Modification enregistrées avec succès !")
      },
      onStartActivity() {
        this.onUpdateActivity(true)
        const node = this.findNode(this.selectedNode.data.id)
        const data = {
          activityId: node.activityId,
          cultureId: node.parent.cultureId,
        }
        this.onSubmit("/exploitation/production/harvest/activity/start", "fetchingStartActivity", data => {
          if (data) {
            this.setHarvestData(data)
            Toast.success('Activité démarrée avec succès !')
          }
        }, data)
      },
      onEndActivity() {
        if (!confirm(`Etes-vous sûr de vouloir clôturer l'activité "${this.selectedNode.data.text}" ?`))
          return
        
        this.onUpdateActivity(true)
        const node = this.findNode(this.selectedNode.data.id)
        const data = {
          activityId: node.activityId,
          cultureId: node.parent.cultureId
        }
        this.onSubmit("/exploitation/production/harvest/activity/close", "fetchingStartActivity", data => {
          if (data) {
            this.setHarvestData(data)
            Toast.success('Activité clôturée avec succès !')
          }
        }, data)
      },
      onStartStep() {
        const node = this.findNode(this.selectedNode.data.id)
        const data = {
          cultureId: node.cultureId
        }
        this.onSubmit(`/exploitation/production/harvest/step-start`, "fetchingStartStep", data => {
          if (data) {
            this.setHarvestData(data)
            Toast.success('Etape de culture démarrée avec succès !')
          }
        }, data)
      },
      
      onEndStep() {
        this.submittedEndCulture = true
        if (!this.validEndCulture()) return
        const node = this.findNode(this.selectedNode.data.id)
        const data = {
          cultureId: node.cultureId,
          warehouseId: this.endHarvestWarehouse,
          realQuantity: this.cultureRealQuantity
        }
        this.onSubmit(`/exploitation/production/harvest/step-close`, "fetchingEndStep", data => {
          if (data) {
            this.setHarvestData(data)
            this.addNewInformationsEndCultureModal = false
            Toast.success('Etape de culture clôturée avec succès !')
          }
        }, data)
      },
      onEnd(fetcher = "fetchingSaveAndLaunchHarvest") {
        let endHarvestQuantities = []
        if (fetcher != "fetchingSaveAndLaunchHarvest") {
          this.submittedAllCutlureQuantities = true
          if (!this.validAllCultureQuantities()) return
          endHarvestQuantities = this.endHarvestCultureQuantities
        }
        const data = {
          endHarvestCultureQuantities: endHarvestQuantities
        }
        console.log(fetcher)
        this.onSubmit(`/exploitation/production/harvest/close`, fetcher, data => {
          if (data) {
            this.setHarvestData(data)
            Toast.success('Production clôturée avec succès !')
            this.addNewInformationsEndHarvestModal = false
          }
        }, data)
      },
      onSubmit(route, fetchingAttribute, callback, data = {}) {
        this[fetchingAttribute] = true
        Api.post(route, {
          exploitationId: this.exploitationId,
          productionId: this.productionHarvest.id,
          harvestId: this.harvestId,
          startDate: this.harvestStartDateTime,
          name: this.harvestName,
          description: this.harvestDescription,
          cultures: this.getHarvestData(),
          ...data
        })
          .then(res => {
            if (res.data.status === 'success' && res.data.data) {
              callback(res.data.data)
              
            }
            else {
              this.error = res.data.error
            }
          })
          .catch(error => {
            this.error = {
              message: 'Echec de la connexion au serveur'
            }
            this.error = error
          })
          .then(() => {
            this[fetchingAttribute] = false
          })
      }
      ,
      onSaveHarvest() {
        this.onSubmit('/exploitation/production/harvest/save', "fetchingSaveHarvest", data => {
          this.setHarvestData(data)
          Toast.success('Récolte enregistrée avec succès !')
        })
      }
      ,
      onSaveAndLaunchHarvest() {
        this.onSubmit('/exploitation/production/harvest/save-start', "fetchingSaveAndLaunchHarvest", data => {
          this.setHarvestData(data)
          Toast.success('Récolte enregistrée et lancée avec succès !')
        })
      }
      ,
      clearStaffAutocompleteInput() {
        this.$refs.staffAutocompleteInput.inputValue = ''
        this.staffSearch = ''
      }
      ,
      addStaff(item) {
        if (!item && this.staffSearch.trim() !== '')
          item = this.staffAutocompleteData.find(
            el => el.name.toLowerCase().includes(this.staffSearch.toLowerCase())
          )
        
        if (item) {
          if (!this.activityStaff.includes(item))
            this.activityStaff.push(item)
        }
        else
          alert('Aucun élément trouvé !')
        
        this.clearStaffAutocompleteInput()
      }
      ,
      removeStaff(item) {
        this.activityStaff = this.activityStaff.filter(el => el.id !== item.id || el.user !== item.user)
      },
      openOtherInformationsModal() {
        if (!confirm(`Etes-vous sûr de vouloir clôturer l'étape "${this.selectedNode.data.text}" ?`))
          return
        this.addNewInformationsEndCultureModal = true
        this.fetchingExistingWarehouse = true
        Api.get('/exploitation/warehouse/list', {
          exploitationId: this.exploitationId,
        })
          .then(res => {
            if (res.data.status === 'success' && res.data.data) {
              this.warehouses = res.data.data.map(ware => ({
                value: ware.id,
                text: ware.name
              }))
            }
            else {
              this.error = res.data.error
            }
          })
          .catch(error => {
            this.error = {
              message: 'Echec de la connexion au serveur'
            }
          })
          .then(() => {
            this.fetchingExistingWarehouse = false
          })
      },
      openOtherModal() {
        this.fetchingSaveAndLaunchHarvest = true
        const cultures = this.harvestData.children.reduce((acc, val) => {
          if (val.status.name != 'TERMINATED')
            return acc.concat({
              cultureId: val.cultureId,
              cultureName: val.name,
            })
          else return acc
        }, [])
        console.log(cultures)
        if (cultures.length == 0) {
          this.onEnd()
        } else {
          this.fetchingSaveAndLaunchHarvest = false
          this.addNewInformationsEndHarvestModal = true
          this.fetchingExistingWarehouse = true
          this.endHarvestCultureQuantities = cultures.map(cult => ({
            ...cult,
            warehouseId: null,
            realQuantity: ''
          }))
          if (this.warehouses.length == 0) {
            Api.get('/exploitation/warehouse/list', {
              exploitationId: this.exploitationId,
            })
              .then(res => {
                if (res.data.status === 'success' && res.data.data) {
                  this.warehouses = res.data.data.map(ware => ({
                    value: ware.id,
                    text: ware.name
                  }))
                }
                else {
                  this.error = res.data.error
                }
              })
              .catch(error => {
                this.error = {
                  message: 'Echec de la connexion au serveur'
                }
              })
              .then(() => {
                this.fetchingExistingWarehouse = false
              })
          } else {
            this.fetchingExistingWarehouse = false
          }
        }
      },
      onModalClose() {
      },
      multiplyWithSurface(element) {
        return Math.ceil(element * this.surfaceParcel)
      },
      getFiles(files) {
        return Promise.all(files.map(file => {
          const reader = new FileReader()
          return new Promise((resolve, reject) => {
            reader.onload = ev => {
              resolve(ev.target.result)
            }
            reader.readAsDataURL(file)
          })
        }))
      },
      async onSubmitAddSpent() {
        this.submittedSpent = true
        if (!this.validSpent())
          return
        
        this.fetchingCreateSpent = true
        
        const base64Files = await this.getFiles(this.spentFiles)
        
        // recuperation de l'emetteur
        const emitter = {
          isExist: this.spentEmitter ? true : false,
          user: this.spentEmitter || this.spentEmitterNew
        }
        // recuperation du beneficiaire
        const receiver = {
          isExist: this.spentReceiver ? true : false,
          user: this.spentReceiver || this.spentReceiverNew
        }
        
        // retrieving the old|new mode
        const cat = {
          isExist: this.spentCategory ? true : false,
          category: this.spentCategory || this.spentCategoryNew
        }
        
        
        Api.post('/exploitation/production/harvest/spent/create', {
          exploitationId: this.exploitationId,
          harvestId: this.harvestId,
          name: this.spentName,
          amount: this.spentCost,
          spentEmitter: emitter,
          spentReceiver: receiver,
          spentCategory: cat,
          spentCategoryId: 2,
          spentDate: this.spentDate + " " + this.spentTime,
          description: this.spentDescription,
          attachments: base64Files
        })
          .then(res => {
            if (res.data.status === 'success' && res.data.data) {
              this.addModalSpent = false
              Toast.success('Dépense enregistrée avec succès')
              this.refreshCategoryList()
              this.refreshSpentCategoryList()
            }
            else {
              this.error = res.data.error
            }
          })
          .catch(error => {
            this.error = {
              message: 'Echec de la connexion au serveur'
            }
          })
          .then(() => {
            this.fetchingCreateSpent = false
          })
      },
      onAddModalSpentClose() {
        this.spentName = ''
        this.spentCost = ''
        this.spentDescription = ''
        this.submittedSpent = false
        this.spentDate = ""
        this.spentTime = ""
        this.spentReceiver = null
        this.spentReceiverNew = ""
        this.spentReceiverNewDisplay = false
        this.spentEmitter = null
        this.spentEmitterNew = ""
        this.spentEmitterNewDisplay = false
        this.spentCategoryNewDisplay = false
      },
      newSpent() {
        this.addModalSpent = true
      },
      validSpent() {
        return this.spentName.trim().length >= 3 &&
          Regex.isPositiveNumber(this.spentCost)
          && (this.spentCategory != null || this.spentCategoryNew.trim().length >= 3)
          && (this.spentEmitter != null || this.spentEmitterNew.trim().length >= 3)
          && (this.spentReceiver != null || this.spentReceiverNew.trim().length >= 3)
      },
      validCulture() {
        return (this.cultureMinEstimation == '' || Regex.isPositiveNumber(this.cultureMinEstimation))
          && (this.cultureMaxEstimation == '' || Regex.isPositiveNumber(this.cultureMaxEstimation))
      },
      validEndCulture() {
        return this.endHarvestWarehouse != null && Regex.isPositiveNumber(this.cultureRealQuantity)
      },
      validActivity() {
        if (!Regex.isPositiveNumber(this.activityDuration))
          return false
        if (!Regex.isPositiveNumber(this.activityCost))
          return false
        for (let i = 0; i < this.activityInputs.length; i++) {
          if (!Regex.isPositiveNumber(this.activityInputs[i].quantity))
            return false
        }
        for (let i = 0; i < this.activityEquipments.length; i++) {
          if (!Regex.isPositiveNumber(this.activityEquipments[i].quantity))
            return false
        }
        return true
      },
      validAllCultureQuantities() {
        this.endHarvestCultureQuantities.forEach(item => {
          if (item.warehouseId == null || Regex.isPositiveNumber(item.realQuantity))
            return false
        })
        return true
      },
      addSpentEmitter() {
        this.spentEmitterNewDisplay = true
      },
      addReceiveEmitter() {
        this.spentReceiverNewDisplay = true
      },
      refreshCategoryList() {
        
        Api.get('/exploitation/member/all', {
          exploitationId: this.exploitationId
        })
          .then(res => {
            if (res.data.status === 'success' && res.data.data) {
              this.spentsMembers = res.data.data
            }
            else {
              this.error = res.data.error
            }
          })
          .catch(error => {
            this.error = {
              message: 'Echec de la connexion au serveur'
            }
          })
          .then(() => {
          })
      },
      refreshSpentCategoryList() {
        
        Api.get('/exploitation/spent-category/list', {
          exploitationId: this.exploitationId
        })
          .then(res => {
            if (res.data.status === 'success' && res.data.data) {
              this.spentCategories = res.data.data
            }
            else {
              this.error = res.data.error
            }
          })
          .catch(error => {
            this.error = {
              message: 'Echec de la connexion au serveur'
            }
          })
          .then(() => {
          })
      },
      
      // Modal
      clearSearchAutocompleteInput() {
        this.$refs.searchAutocompleteInput.inputValue = ''
        this.search = ''
      },
      onAddModalClose() {
        this.toAdd = []
        this.clearSearchAutocompleteInput()
      },
      removeItem(item) {
        this.toAdd = this.toAdd.filter(el => el.id !== item.id)
      },
      submitSearch(item) {
        if (!item && this.search.trim() !== '')
          item = this.searchAutocompleteData.find(
            el => el.name.toLowerCase().includes(this.search.toLowerCase())
          )
        
        if (item) {
          if (!this.toAdd.includes(item))
            this.toAdd.push(item)
        }
        else
          alert('Aucun élément trouvé !')
        
        this.clearSearchAutocompleteInput()
      },
      onSubmitAdd() {
        if (!this.toAdd.length)
          return
        
        const parentType = this.nodeTypes[this.selectedNode.data.type]
        const type = this.nodeTypes[parentType.children[0]]
        if (this.selectedNode.data.type == "Culture") {
          this.toAdd.forEach(item => {
            const node = new TreeNode({
              id: parentType.children[0] + '-' + item.id,
              icon: type.icon,
              text: item.name,
              type: parentType.children[0],
              parent: this.selectedNode.data.ref,
              actions: [{
                icon: 'fa-minus',
                text: 'Supprimer act',
              }],
              ctxMenuItems: [
                {code: "DEL", label: "Supprimer l'activité"}
              ],
              //to verifiy if it's an activity to display the real color in the TreeNode component
              isActivity: true
            })
            if (type.refKey)
              node[type.refKey] = item.id
            
            if (type.properties)
              type.properties.forEach(
                prop => node[prop.key] = typeof prop.defaultValue === "function"
                  ? prop.defaultValue(item)
                  : prop.defaultValue
              )
            this.selectedNode.data.children.push(node)
          })
        }
        this.selectedNode.expand()
        this.addNodeModal = false
      },
    }
  }
</script>
<style>
  .tree-node{
    display: inherit !important;
  }
</style>
<style scoped>
  .autocomplete{
    width: 100%;
    border-radius: 0;
  }
  
  strong-display{
    color: grey;
    font-family: "Segoe UI Historic";
  }
  
  default-unit{
    font-size: 20px;
    font-family: Candara;
    font-weight: bold;
    text-decoration: underline;
  }
  
  text-recommended{
    font-style: italic;
    margin-top: 10px;
    font-weight: 600;
    color: #009000;
  }
  
  text-recommended::after,
  text-recommended::before{
    content: " \2022 ";
  }
  
  .add-button{
    height: 30px;
    width: 30px;
    margin-top: 23px;
    margin-left: 10px;
    margin-right: 2px;
  }
</style>
